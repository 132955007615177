import React, { Component, useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Table } from 'semantic-ui-react'

import DigitalImage from '../../assets/img/digital.png';
import MagImage from '../../assets/img/magazine.png';
import BundleImage from '../../assets/img/bundle.png';
import hex2rgba from 'hex2rgba';

export const PurchaseTableHeader = (props) => {
  const { t } = useTranslation();
  const style = {
    tableHeader: {
      backgroundColor: props.publication.backgroundColour,
      padding: '2em',
      fontSize: '17px',
      color: '#fff',
    },
  };

  return  <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                textAlign='center'
                style={style.tableHeader}
              ></Table.HeaderCell>
              <Table.HeaderCell
                textAlign='center'
                style={style.tableHeader}
              >
                {t('subscription')}
              </Table.HeaderCell>
              <Table.HeaderCell
                textAlign='center'
                style={style.tableHeader}
              >
                {t('price')}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
}

const PURCHASE_TABLE_ROW_TYPE = {
    DIGITAL: 'DIGITAL',
    PRINT: 'PRINT',
    BUNDLE: 'BUNDLE'
  }
  
export const PurchaseTableGenericRow = (props) => {
    const bg05 = hex2rgba(
      props.publication.backgroundColour,
      0.05,
    )
    const bg15 = hex2rgba(
      props.publication.backgroundColour,
      0.05,
    )
  
    const style = {
      changecolor: {
        color: '#828282',
        fontSize: '18px',
      },
      tableContentdigital: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: bg05,
      },
      tableContentprint: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: '#f3f3f3',
      },
      tableContentbundle: {
        padding: '2em',
        fontSize: '8px',
        backgroundColor: bg15,
      },
    };
  
    const purchase_type_key = props.keyTarget[1]
    let purchase_table_row_type = null
    if( purchase_type_key == 'P' ) purchase_table_row_type = PURCHASE_TABLE_ROW_TYPE.PRINT
    if( purchase_type_key == 'D' ) purchase_table_row_type = PURCHASE_TABLE_ROW_TYPE.DIGITAL
    if( purchase_type_key == 'B' ) purchase_table_row_type = PURCHASE_TABLE_ROW_TYPE.BUNDLE
  
    let styleLocal = null
    let imgLocal = null
    if( purchase_table_row_type === PURCHASE_TABLE_ROW_TYPE.DIGITAL ){
      styleLocal = style.tableContentdigital
      imgLocal = MagImage
    }
      
    if( purchase_table_row_type === PURCHASE_TABLE_ROW_TYPE.PRINT ){
      styleLocal = style.tableContentprint
      imgLocal = DigitalImage
    }
      
    if( purchase_table_row_type === PURCHASE_TABLE_ROW_TYPE.BUNDLE ){
      styleLocal = style.tableContentbundle
      imgLocal = BundleImage
    }
    
  
    const imgWidth = 
      purchase_table_row_type === PURCHASE_TABLE_ROW_TYPE.BUNDLE? '100px': '50px'
  
    if( props.price == null || props.price.length == 0 ) return null
  
    if( props.isFree != null ){
      if( props.isFree === false ) return null
    }
    const value = props.keyTarget + props.price
    return <Table.Row>
              <Table.Cell
                textAlign='center'
                style={styleLocal}
              >
                <img src={imgLocal} style={{ width: imgWidth }} alt='' />
              </Table.Cell>
              <Table.Cell
                textAlign='center'
                style={styleLocal}
              >
                <Form.Field>
                  <Checkbox
                    style={style.changecolor}
                    radio
                    label={props.label}
                    name='checkboxSelection'
                    value={props.keyTarget + props.price}
                    checked={props.checkboxSelection == value}
                    onChange={(e,{value}) => props.onChange(e, {value})}
                  />
                </Form.Field>
              </Table.Cell>
              <Table.Cell
                textAlign='center'
                style={styleLocal}
              >
                <span style={{ fontSize: '18px' }}>{props.isFree==null?props.currency:''}{props.price}</span>
              </Table.Cell>
            </Table.Row>
  }

export const PurchaseTable = (props) => {
  const { publication } = props
  const { t } = useTranslation();

  if( props.country == null ) return null

  // Extract priceList
  const priceListObj = publication.pricePerCountryList.find(item => item.key === props.country)
  const priceList = priceListObj.priceList
  const currency = priceListObj.currency
  

  if (
    !priceList.subFree &&
    priceList.price === undefined &&
    priceList.priceOneYear === undefined &&
    priceList.priceSixMonth === undefined &&
    priceList.priceThreeMonth === undefined &&
    priceList.printPrice === undefined &&
    priceList.printPriceThreeMonth === undefined &&
    priceList.printPriceOneYear === undefined &&
    priceList.printPriceSixMonth === undefined &&
    priceList.printDigPrice === undefined &&
    priceList.printDigPriceOneYear === undefined &&
    priceList.printDigPriceSixMonth === undefined &&
    priceList.printDigPriceThreeMonth === undefined &&
    priceList.priceOneYearRecurring === undefined &&
    priceList.priceSixMonthRecurring === undefined &&
    priceList.priceThreeMonthRecurring === undefined &&
    priceList.printPriceThreeMonthRecurring === undefined &&
    priceList.printPriceOneYearRecurring === undefined &&
    priceList.printPriceSixMonthRecurring === undefined &&
    priceList.printDigPriceOneYearRecurring === undefined &&
    priceList.printDigPriceSixMonthRecurring === undefined &&
    priceList.printDigPriceThreeMonthRecurring === undefined &&
    !priceList.printSubFree &&
    !priceList.printDigSubFree
  ) {
    
    return <div style={{ padding: '25px' }}>
            <h3>{t('unavailableSubscriptionInCountry')}</h3>
          </div>
    
  }

  const purchase_list = [
    // PRINT
    { label: t('fps'),   keyTarget: 'FPN', price: 'Free', isFree: priceList.printSubFree },
    { label: t('1yps'),  keyTarget: '1PN', price: priceList.printPriceOneYear },
    { label: t('1yrps'), keyTarget: '1PR', price: priceList.printPriceOneYearRecurring },
    { label: t('6mps'),  keyTarget: '6PN', price: priceList.printPriceSixMonth },
    { label: t('6mrps'), keyTarget: '6PR', price: priceList.printPriceSixMonthRecurring },
    { label: t('3mps'),  keyTarget: '3PN', price: priceList.printPriceThreeMonth },
    { label: t('3mrps'), keyTarget: '3PR', price: priceList.printPriceThreeMonthRecurring },
    { label: t('1mps'),  keyTarget: '2PN', price: priceList.printPrice },

    // DIGITAL
    { label: t('fds'),   keyTarget: 'FDN', price: 'Free', isFree: priceList.subFree },
    { label: t('1yds'),  keyTarget: '2PN', price: priceList.priceOneYear },
    { label: t('1yrds'), keyTarget: '1DR', price: priceList.priceOneYearRecurring },
    { label: t('6mds'),  keyTarget: '6DN', price: priceList.priceSixMonth },
    { label: t('6mrds'), keyTarget: '6DR', price: priceList.priceSixMonthRecurring },
    { label: t('3mds'),  keyTarget: '3DN', price: priceList.priceThreeMonth },
    { label: t('3mrds'), keyTarget: '3DR', price: priceList.priceThreeMonthRecurring },
    { label: t('1mds'),  keyTarget: '2DN', price: priceList.price },
    
    // BUNDLE
    { label: t('fbs'),   keyTarget: 'FBN', price: 'Free', isFree: priceList.printDigSubFree },
    { label: t('1ybs'),  keyTarget: '1BN', price: priceList.printDigPriceOneYear },
    { label: t('1yrbs'), keyTarget: '1BR', price: priceList.printDigPriceOneYearRecurring },
    { label: t('6mbs'),  keyTarget: '6BN', price: priceList.printDigPriceSixMonth },
    { label: t('6mrbs'), keyTarget: '6BR', price: priceList.printDigPriceSixMonthRecurring },
    { label: t('3mbs'),  keyTarget: '3BN', price: priceList.printDigPriceThreeMonth },
    { label: t('3mrbs'), keyTarget: '3BR', price: priceList.printDigPriceThreeMonthRecurring },
    { label: t('1mbs'),  keyTarget: '2BN', price: priceList.printDigPrice },
  ]
  
  return <div style={{ padding: '25px' }}>
          <Table celled>
            <PurchaseTableHeader publication={props.publication} />
            <Table.Body>
              {purchase_list.map( (item, idx) => 
                {
                  // Price not configured
                  if( item.price == null || item.price.length == 0 ) return null;

                  // Treating free
                  if( item.isFree != null && item.isFree === false) return null;

                  
                  return <PurchaseTableGenericRow key={idx}
                          checkboxSelection={props.checkboxSelection}
                          publication={props.publication}
                          onChange={(e, {value}) => props.onChange(e, {value})}
                          currency={currency}
                          {...item}
                        />
                }
              )}
            </Table.Body>
            <Table.Footer></Table.Footer>
          </Table>
        </div>
}
