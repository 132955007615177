import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Button, Table, Checkbox, 
    Grid, Header, Image, Menu, Input, Form, } from 'semantic-ui-react';
import getTitles from '../common/Titles';
import RenderFieldLeftBasic from '../common/NormalForm/renderFieldLeftBasic';

export const Login = (props) => {
    const { t } = useTranslation();
  
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
  
    // forgotten Email
    const [ forgottenEmail, setForgottenEmail ] = useState('');
    const [ showForgotten, setShowForgotten ] = useState(false);
    const [ forgottenEmailError, setForgottenEmailError ] = useState('');
    const [ emailResetMessage, setEmailResetMessage ] = useState('');
  
  
    const forgottenClick = (e) => {
      e.preventDefault();
      const buyerDets = {
        email: forgottenEmail,
        publicationID: props.publication._id,
      };
      axios.post('/api/order/forgot', buyerDets).then((res) => {
        if (res.data === 'No such user') {
          setForgottenEmailError('We cannot find a user with that email address in the system. Please try again or sign up to the right.')
          setEmailResetMessage('')
        } else {
          setForgottenEmailError('')
          setEmailResetMessage('Thank you. An email has been sent to your entered email address with further instructions')
        }
      });
    };
  
    return <div style={{ padding: '25px' }}>
                <Header as='h3'>{t('returningCustomers')}</Header>
                  <Input name='emailExisting' type='email'
                    label={{ basic: true, content: 'Email Address *' }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Email' fluid
                    error={props.errors.emailExisting != null}
                  />
                  {props.errors.emailExisting && (
                    <div
                      className='ui pointing red basic label'
                      style={{ marginBottom: '10px' }}
                    >
                      {props.errors.emailExisting}
                    </div>
                  )}
                  <br />
                  <Input name='passwordExisting' type='password'
                    label={{ basic: true, content: 'Password *' }}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Password' fluid
                    error={props.errors.passwordExisting}
                  />
                  {props.errors.passwordExisting && (
                    <div
                      className='ui pointing red basic label'
                      style={{ marginBottom: '10px' }}
                    >
                      {props.errors.passwordExisting}
                    </div>
                  )}
                  <br />
                  <Button type='button' fluid
                    color='black' onClick={(e) => props.onLoginClick(e, email, password)}> 
                    {t('login')}
                  </Button>
                  <p style={{ marginTop: '5px', textAlign: 'right', cursor: 'pointer', }}>
                    <Menu.Item onClick={() => setShowForgotten(true)}>
                      {t('forgotPwd')}
                    </Menu.Item>
                  </p>
                  {showForgotten &&
                  <>
                    <Input name='forgottenEmail' type='email'
                      label={{ basic: true, content: 'Email Address *' }}
                      value={forgottenEmail}
                      onChange={(e) => setForgottenEmail(e.target.value)}
                      placeholder='Email' fluid
                      error={forgottenEmailError}
                    />
                    {forgottenEmailError && (
                      <div className='ui pointing red basic label'>
                        {forgottenEmailError}
                      </div>
                    )}
                    <br />
                    <Button color='black' type='button' fluid
                      onClick={(e) => forgottenClick(e)}>
                      Reset Password
                    </Button>
                    <p>{emailResetMessage}</p>
                  </>
                  }
                </div>
}





export const Register = (props) => {
  const { t } = useTranslation();
  const [ emailError, setEmailError ] = useState({first: '', second: ''});
  const [ passwordError, setPasswordError ] = useState('');


  // Confirm Password Validation
  const onConfirmPasswordBlur = (e) => {
    const passwordErrorLocal = props.password === e.target.value?'':'Passwords must match';
    setPasswordError(passwordErrorLocal);
  };


  // Email Validation
  const onEmailFirstBlur = async (e) => {
    // 1. Check email first error required
    if (e.target.value === '') {
      setEmailError({first: 'Required', second: ''})
      return
    }

    // 2. Check uniquess    
    try{
      await axios(`/api/order/email/${e.target.value}/${props.publication._id}`)
    }catch(e){
      setEmailError({first: "Log In under 'Returning Customers'", second: ''})
      return
    }


    // 3. Check both emails match
    const email1 = e.target?e.target.value:props.email1
    const emailErrorLocal = props.email2 === email1?'':'Emails must match'
    if( emailError ){
      setEmailError({first: "", second: emailErrorLocal})
      return
    }

    // No error found
    setEmailError({first: "", second: ""})
  };

  // Confirm Email Validation
  const onEmailBlur = (e) => {
    if( emailError.first.length > 0 ) return
    // Proceed
    const emailErrorLocal = props.email === e.target.value?'':'Emails must match';
    setEmailError({first: "", second: emailErrorLocal})
  };
  
  return <div style={{ padding: '25px' }}>
              <Header as='h3'>{t('newCustomer')}</Header>
              <div>
                <Input
                  name='email'
                  type='email'
                  label={{ basic: true, content: t('emailAddr') + ' *' }}
                  value={props.email}
                  onChange={(e) => props.onChange(e)}
                  onBlur={e => onEmailFirstBlur(e)}
                  placeholder='Email'
                  autoComplete='new-email'
                  fluid
                  error={props.errors.email}
                />
                {emailError.first && (
                  <div className='ui pointing red basic label'>
                    {emailError.first}
                  </div>
                )}
              </div>
              <br />
              <RenderFieldLeftBasic
                name='email2'
                type='email'
                label={t('confirmEmail') + ' *'}
                value={props.email2}
                onChange={(e) => props.onChange(e)}
                onBlur={e => onEmailBlur(e)}
                autoComplete='new-email'
                placeholder={t('confirmEmail')}
                error={emailError.second  }
              />
              <br />
              <Form.Group>
                <Form.Select
                  options={getTitles()}
                  onChange={(e, {value}) => 
                    {e.target.name='title'; e.target.value=value; props.onChange(e)} }
                  placeholder={t('title')}
                  selection
                  name='title'
                  value={props.title}
                  error={props.errors.title}
                  width={4}
                  style={{ minWidth: 0 }}
                  className='customer--title'
                />
                <Form.Field width={12}>
                  <Input
                    name='firstName'
                    label={{ basic: true, content: t('firstName') + ' *' }}
                    value={props.firstName}
                    onChange={(e) => props.onChange(e)}
                    placeholder={t('firstName')}
                    error={props.errors.firstName}
                    className='customer--fname'
                  />
                  {props.errors.firstName && (
                    <div className='ui pointing red basic label'>
                      {props.errors.firstName}
                    </div>
                  )}
                </Form.Field>
                <br />
              </Form.Group>
              <div>
                <Input
                  name='lastName'
                  label={{ basic: true, content: t('lastName') + ' *' }}
                  value={props.lastName}
                  onChange={(e) => props.onChange(e)}
                  placeholder={t('lastName')}
                  fluid
                  error={props.errors.lastName}
                />
                {props.errors.lastName && (
                  <div className='ui pointing red basic label'>
                    {props.errors.lastName}
                  </div>
                )}
              </div>
              <br />
              <div>
                <Input
                  name='mobile'
                  label={{ basic: true, content: t('mobile') }}
                  value={props.mobile}
                  onChange={(e) => props.onChange(e)}
                  placeholder={t('mobile')}
                  fluid
                  error={props.errors.mobile}
                />
                {props.errors.mobile && (
                  <div className='ui pointing red basic label'>
                    {props.errors.mobile}
                  </div>
                )}
              </div>
              <br />
              <div>
                <Input name='password' type='password'
                  label={{ basic: true, content: t('choosePwd') + ' *' }}
                  value={props.password}
                  onChange={(e) => props.onChange(e)}
                  placeholder={t('password')}
                  autoComplete='new-password'
                  fluid error={props.errors.password} />
                {props.errors.password && (
                  <div className='ui pointing red basic label'>
                    {props.errors.password}
                  </div>
                )}
              </div>
              <br />
              <div>
                <Input name='password2' type='password'
                  label={{ basic: true, content: t('confirmPwd') + ' *' }}
                  value={props.password2}
                  onChange={(e) => props.onChange(e)}
                  placeholder={t('confirmPwd')}
                  fluid onBlur={(e) => onConfirmPasswordBlur(e)}
                  error={passwordError.length > 0}
                />
                {passwordError && (
                  <div className='ui pointing red basic label'>
                    {passwordError}
                  </div>
                )}
              </div>
              <br />
            </div>
}

export const ReturningCustomer = (props) => {
  const { t } = useTranslation();
  return <Grid>
          <Grid.Row style={{ padding: ' 25px'}}>
            <Grid.Column mobile={8} tablet={8} computer={8} style={{ marginTop: '10px'}}>
              <div>
                <Header as='h3'>{t('successLoggedIn')}</Header>
                <p>
                  {t('welcomeBack')}, {props.title} {props.firstName}{' '}{props.lastName}
                </p> 
              </div>             
            </Grid.Column>

            <Grid.Column mobile={8} tablet={8} computer={8} style={{ marginTop: '10px'}}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                <div>Subscriber since: <b>{props.firstSubscritionStartDate}</b></div>
                <div style={{ marginTop: '10px'}}>Current subscription expiry date: <b>{props.lastSubscritionEndDate}</b></div>
              </div>
            </Grid.Column>

          </Grid.Row>
        </Grid>
}

export const Customer = (props) => {

  if( props.showReturnCustomerDetails){
    return <Grid.Row verticalAlign='top'>
              <Grid.Column>
                <Grid.Row>
                  <ReturningCustomer 
                    title={props.title} firstName={props.firstName} lastName={props.lastName} 
                    firstSubscritionStartDate={props.firstSubscritionStartDate}
                    lastSubscritionEndDate={props.lastSubscritionEndDate} />
                </Grid.Row>
              </Grid.Column>
          </Grid.Row>
  }


  return  <Grid.Row verticalAlign='top'>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Register errors={props.errors} onChange={props.onChange} {...props}/>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8} style={{ height: '100%' }}>
              <Login onLoginClick={(e, email, password) => props.onLoginClick(e, email, password)}
                                      errors={props.errors}
                                      publication={props.publication} />
            </Grid.Column>
          </Grid.Row>
}