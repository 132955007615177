import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Input } from 'semantic-ui-react';
import { PostalCodeLookup } from './StoreFrontBasics';

export const BillingAddress = (props) => {
    const { t } = useTranslation();
  
    const style = {
      container: {
        backgroundColor: props.publication.backgroundColour,
        color: '#fff',
        padding: '1em',
        fontSize: '20px',
        width: '100%',
      },
    };
  
  return <>
              <Grid.Row>
                <div style={style.container}>{t('billingAddr')}</div>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div style={{ padding: '25px' }}>
                    {props.showPCLookup && 
                      <PostalCodeLookup 
                        onSelected={(e,{value,text}) => props.handleBillingChange(e,{value,text})} />                      
                    }
                    <Input
                      label={{ basic: true, content: t('companyName') }}
                      name='billingCompanyName'
                      value={props.state.billingCompanyName}
                      onChange={props.onChange}                    
                      fluid
                    />
                    <br />
                    <Input
                      label={{ basic: true, content: t('addr1') + ' *' }}
                      name='billingAddress1'
                      value={props.state.billingAddress1}
                      onChange={props.onChange}
                      fluid
                    />
                    <br />
                    <Input
                      label={{ basic: true, content: t('addr2') }}
                      name='billingAddress2'
                      value={props.state.billingAddress2}
                      onChange={props.onChange}
                      fluid
                    />
                    <br />
                    <Input
                      label={{ basic: true, content: t('addr3') }}
                      name='billingAddress3'
                      value={props.state.billingAddress3}
                      onChange={props.onChange}
                      fluid
                    />
                    <br />
                    <Input
                      label={{ basic: true, content: t('townCity') }}
                      name='billingTown'
                      value={props.state.billingTown}
                      onChange={props.onChange}
                      fluid
                    />
                    <br />
                    {props.state.country === 'US' && (
                      <>
                        <Input
                          label={{ basic: true, content: 'State' }}
                          name='billingState'
                          value={props.state.billingState}
                          onChange={props.onChange}
                          fluid
                        />
                        <br />
                      </>
                    )}
                    {props.country === 'US' && (
                      <>
                        <Input
                          label={{ basic: true, content: 'Zip Code *' }}
                          name='billingPostcode'
                          value={props.state.billingPostcode}
                          onChange={props.onChange}
                          fluid
                        />
                        <br />
                      </>
                    )}
                    {props.country !== 'US' && (
                      <>
                        <Input
                          label={{ basic: true, content: t('postcode') + ' *' }}
                          name='billingPostcode'
                          value={props.state.billingPostcode}
                          onChange={props.onChange}
                          fluid
                        />
                        <br />
                      </>
                    )}
                    <Input
                      label={{ basic: true, content: t('country') }}
                      name='deliveryCountry'
                      fluid
                      value={props.state.countryText}
                      disabled={true}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
            </>
  
  }